.bD_sectionTitle::after {
    content: "";
    display: block;
    padding: 2px 0;
    border-radius: 5px;
    margin: auto;
    margin-top: 5px;
    width: 50%;
    background-color: #00c5ccb1;
}

@media all and (max-width:576px) {
    .mobileOnlyCenter {
        justify-content: center !important;
    }
}

.cartCount {}




.offerHeaderTop {
    background-color: #00c4cc;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 35px;
    padding: 0 1rem;
    margin: 0 auto;
}



.offerHeaderTop p {
    font-weight: 900;
    font-size: 18px;
    color: #fffffff8;
    text-transform: capitalize;
}

.offerHeaderTop>span {
    position: absolute;
    right: 2%;
    bottom: 5px;
    display: inline-block;
    line-height: 1;
    padding: 3px;
    cursor: pointer;


}

.offerHeaderTop>span>svg {
    fill: rgb(255, 255, 255);
    width: 20px;
    height: 20px;
}

.filterCategory {
    color: #000;
    font-weight: 400;
    text-transform: capitalize;
    cursor: pointer;
    transition: color 0.15s ease, font-weight 0.15s ease;

}

.filterCategory:hover {
    font-weight: 500;
    color: #1ac4cc;
}