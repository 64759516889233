.variantSelectorContainer {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    align-items: center;
    margin: 10px 0;
}



.variantLable {
    display: inline-flex;
    min-width: 40px;
    padding: 4px 8px;
    height: 30px;

    border: 1px solid #cccccc75;
    border-radius: 5px;

    justify-content: center;
    align-items: center;

    line-height: 1.5;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;

}

.variantLable:hover {
    border-color: #00c4cc;
}

.selected {
    border-color: #00c4cc;
    background-color: #00c4cc;
    color: #fff;
}


.variantColorContainer {
    display: flex;
    overflow-x: auto;
    margin: 10px 0;
    color: red;

}

@media screen and (min-width:768px) {
    .variantColorContainer {
        flex-wrap: wrap;

    }
}

.variantColorImg {
    width: 80px;
    height: 80px;
    cursor: pointer;
    margin: 5px 8px 5px 0px;
    /* //trbl */
}

.variantColorImg img {
    display: block;
    height: 100%;
    object-fit: cover;
}



.variantColorSelector {
    display: inline-flex;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #cccccc75;
    cursor: pointer;
}

.variantColorSelector:hover {
    border-color: #00c4cc;
}

.colorSelected {
    border-color: #000000;
}

/* add quantity btn in product details page */
.add_quant_container {
    display: flex;
    border: 1px solid #efefef;
    align-content: center;
    justify-content: space-around;



    height: 45px;

    width: 150px;
    background: #ffffff;
    font-size: 14px;
    font-weight: 600;


}

.add_quant_dec,
.add_quant_inc {

    padding: 5px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    transition: all 0.5s ease 0s;
    cursor: pointer;



}

.add_quant_value {

    font-weight: 700;
    padding: 3px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #efefef;
    border-top: 0;
    border-bottom: 0;
    min-width: 20px;



}


.add_quant_inc:hover,
.add_quant_dec:hover {
    background-color: #00c4cc;
    color: #fff;
}


.shippingDetailsContainer {
    margin-top: 20px;

    display: flex;
    justify-content: space-evenly;
    font-size: 18px;
    padding: 10px 0;

}

.shippingDetailsContainer p {
    margin: 0px;
    color: #222;
    font-weight: 400;

}

.shippingDetailsContainer p span {

    color: #666;
    font-weight: 300;
}


.selectStyle {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #222222;
    font-size: 14px;
    height: 40px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    border: 1px solid #ffffff;
}

.moreInfo {
    padding: 0px 15px;
    list-style: disc !important;

}

.moreInfo ul li {
    list-style: disc !important;
    padding-left: 0;

}